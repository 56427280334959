<template>
  <header v-if="header" class="Header">
    <div class="HeaderParent">
      <div class="Header_Left">
          <div class="Header_Left_Top">
            <router-link :to="'/'">
              <img class="Header_Left_Top_Logo" :src="urlBase+header.Logo.url"  :alt="'Dig Inclusion Home'" />
            </router-link>
          </div>
      </div>
      <div class="Header_Right" >
        <a v-if="showLinks" href="" :class="['menu', menuOpen?'openMenu':'closedMenu']" @click.prevent="toggleNav" :aria-expanded="menuOpen">
          {{menuOpen?'Close Menu':'Open Menu'}}
        </a>
        <nav v-if="showLinks" ref="navigation" :class="[menuOpen?'open':'closed']">
          <div class="navDiv">
            <li class="navDiv_child" v-for="(link, i) in header.MenuLinks" :key="i">
              <router-link :class="[menuOpen?'open':'closed']" :aria-label="`${link.Text}`" :to="link.Link" @click.prevent="menuOpen = false">{{link.Text}}</router-link>
            </li>
          </div>
        </nav>
      </div>
    </div>
  </header>
</template>

<script>
//import Block from './UI/Block';
import { useQuery } from "@vue/apollo-composable";
import { computed } from 'vue';
import gql from 'graphql-tag'

export default {
  name: 'HeaderNew',
  components: {
    
  },
  data() {
    return {
      urlBase: process.env.VUE_APP_STRAPIURL,
      menuOpen: false
    };
  },
  setup() {
    const { result } = useQuery(gql`
      query {
        headerNew {
          Logo {
            url,
            alternativeText
          }
          MenuLinks {
            Link,
            Text
          }
        }
      }
    `);

    const header = computed(() => result.value?.headerNew);

    return { header };
  },
  methods: {
    toggleNav() {
      this.menuOpen = !this.menuOpen;
      setTimeout( () => {
        if( this.menuOpen ) {
          this.$refs.navigation.focus();
        }
      }, 100 );
    },
  },
  props: {
    showLinks: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

@import '@/assets/styles/variables/_colors.scss';
.navDiv {
  display: inline-block;
  &_child {
    display: inline-block;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    padding: 4px 8px;
  }
}
.HeaderParent {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  min-height: 96px;
  height: 96px;
  padding-left: 80px;
}

.Header {
  background-color: $dig-new-dark-blue;

  &_Left {
    flex-grow: 1;

    &_Top {
      margin-left: 15px;
      &_Logo {
        width: 213px;
      }
    }
  }

  &_Right {
    flex-grow: 1;
    nav {
      font-family: 'Asap';
      text-align: right;
      height: 24px;
      padding-right: 80px;
      a {
        display: inline-block;
        color: white;
        margin-right: 24px;
        font-size: 18px;
        font-weight: 400;

        text-decoration: none;

        &.router-link-active, &:hover, &:focus {
          color: $dig-orange;
          border-bottom: 2px solid $dig-orange;
        }
      }
    }
      
  }
}

@media screen and (min-width: 1050px) {
  a.menu {
    display: none;
  }
}


@media screen and (max-width: 1050px) {
  .navDiv {
    position: absolute;
    left: 0;
    top: 96px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    text-align: center;
    z-index: 2;
    height: 1500vh;
    max-width: 100vw;
    &_child {
      margin-top: 5px;
      margin-bottom: 5px;
      width: 100vw;
      padding: 0;
    }
    background-color: $dig-dark;
  }
  .openMenu {
    color: $dig-orange;
  }
  .closedMenu {
    color: white;
  }
  .Header {
    &_Left {
      position: absolute;
      left: 8px;
    }
    &_Right {
      .closed {
        display: none;
      }

      nav {
        margin-top: 50px;
        
        a {
          margin-right: 0px;
        }
      }

      .open {
        display: block;
        text-align: center;
        padding-top: 10px;
        min-height: 30px;
        font-size: 1.2em;
        border-bottom: 2px solid transparent;
      }
      
      a.menu {
        font-size: 1.2em;
        font-weight: 500;
        position: absolute;
        right: 10px;
        top: 40px;

        text-decoration: none;
      }
    }
  }
}

</style>
