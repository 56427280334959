<template>
  <div :class="['Block', `_bg-${bg}`, `_bgimg-${bgimg}`]">
    <div :class="['Block_Inner', nopad?'_nopad':'', nogap?'_nogap':'_gap', flex?'_flex':'', shorter?'_shorter':'']">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Block',
  props: {
    bg: {
      type: String,
      default: 'transparent'
    },
    bgimg: {
      type: String,
      default: 'none'
    },
    nopad: {
      type: Boolean,
      default: false
    },
    nogap: {
      type: Boolean,
      default: false
    },
    flex: {
      type: Boolean,
      default: false
    },
    shorter: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

@import '@/assets/styles/variables/_colors.scss';

.Block {

  &._bg-blue {
    background: $dig-blue;
    color: #FFFFFF;
    input::placeholder, input::-webkit-input-placeholder, input::-moz-placeholder, input:-ms-input-placeholder, input:-moz-placeholder {
      color: #FFFFFF;
    }
  }
  &._bg-new-blue {
    background: $dig-new-blue;
    color: #FFFFFF;
    input::placeholder, input::-webkit-input-placeholder, input::-moz-placeholder, input:-ms-input-placeholder, input:-moz-placeholder {
      color: #FFFFFF;
    }
  }

  &._bg-new-dark-blue {
    background: $dig-new-dark-blue;
    color: #FFFFFF;
    input::placeholder, input::-webkit-input-placeholder, input::-moz-placeholder, input:-ms-input-placeholder, input:-moz-placeholder {
      color: #FFFFFF;
    }
  }

  &._bg-new-cream {
    background: $dig-new-cream;
    color: $dig-new-dark-blue;
    input::placeholder, input::-webkit-input-placeholder, input::-moz-placeholder, input:-ms-input-placeholder, input:-moz-placeholder {
      color: $dig-new-dark-blue;
    }
  }

  &._bg-new-grey {
    background: $dig-new-grey;
    color: $dig-new-dark-blue;
    input::placeholder, input::-webkit-input-placeholder, input::-moz-placeholder, input:-ms-input-placeholder, input:-moz-placeholder {
      color: $dig-new-dark-blue;
    }
  }

  &._bg-orange {
    background: $dig-orange;
    color: $dig-new-dark-blue;
    input::placeholder, input::-webkit-input-placeholder, input::-moz-placeholder, input:-ms-input-placeholder, input:-moz-placeholder {
      color: $dig-new-dark-blue;
    }
  }

  &._bg-purple {
    background: $dig-purple;
    color: #FFFFFF;
    input::placeholder, input::-webkit-input-placeholder, input::-moz-placeholder, input:-ms-input-placeholder, input:-moz-placeholder {
      color: #FFFFFF;
    }
  }

  &._bg-grey {
    background: $dig-new-dark-blue;
    color: #FFFFFF;
    input::placeholder, input::-webkit-input-placeholder, input::-moz-placeholder, input:-ms-input-placeholder, input:-moz-placeholder {
      color: #FFFFFF;
    }
  }

  &._bg-purple {
    background: $dig-purple;
    color: #FFFFFF;
  }

  &._bg-lightgrey {
    background: lighten($dig-new-dark-blue, 75%);
    color: $dig-new-dark-blue;
    input::placeholder, input::-webkit-input-placeholder, input::-moz-placeholder, input:-ms-input-placeholder, input:-moz-placeholder {
      color: $dig-new-dark-blue;
    }
  }

  &._bg-tertiary {
    background: #97C3B5;
    color: $dig-new-dark-blue;
  }

  &._bg-tertiary-light {
    background: lighten(#97C3B5, 10%);
    color: $dig-new-dark-blue;
  }

  &._bgimg-blob {
    background-image: url('../../assets/files/digblob.png');
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: bottom;
    background-size: 40%;
  }

  &._bgimg-circuit {
    background-image: url('../../assets/files/digcircuit.png');
    background-repeat: no-repeat;
    background-position-x: left;
    background-position-y: center;
    background-size: 50%;
  }

  &._bgimg-circuit-thin {
    background-image: url('../../assets/files/dig-thin-circuit-new.svg');
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    background-size: 70%;
  }

  &._bgimg-circuit-big {
    background-image: url('../../assets/files/digcircuit.png');
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    background-size: 70%;
  }

  &._bgimg-baby {
    background-image: url('../../assets/files/baby.png');
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: center;
    background-size: 35%;
  }

  &_Inner {
    max-width: 1280px;
    width: 100%;
    padding: 32px 32px;
    margin: auto;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;

    box-sizing: border-box;

    &._nopad {
      padding: 0 32px;
    }
    &._shorter {
      padding: 8px 32px;
    }
    &._gap {
      gap: 64px;
    }
    &._nogap {
      gap: 0;
    }

    &._flex {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
    }
  }
}

@media screen and (max-width: 1050px) {
  .Block {
    background-image: none !important;
    &_Inner {
      display: block;
      width: 100%;
      margin: unset;

      &._nopad {
        padding: 1px 32px;
      }
    }
  }
}

</style>
